<template>
  <div class="pageWrapper">
    <HeadNew
        title="Сообщения"
    />

    <div class="main_container tabs">
      <button
          :class="{ selected: state.tab === 'all' }"
          @click="router.replace({name: 'ChatList', query: {tab: 'all'}})"
      >
        Все
      </button>
      <button
          :disabled="unreadCount === 0"
          :class="{ selected: state.tab === 'unread', disabled: unreadCount === 0}"
          @click="router.replace({name: 'ChatList', query: {tab: 'unread'}})"
      >
        Непрочитанные
        <span class="redCircle" v-if="unreadCount">
          {{ unreadCount }}
        </span>
      </button>
    </div>

    <div class="pageWrapperOverflow">
        <RecycleScroller
            v-if="!state.isLoading"
            class="holder_in main_container"
            :items="tabChats"
            :item-size="82"
            key-field="id"
            :buffer="480"
        >
          <template v-slot="{item}">
            <ChatLine
                :key="item.id"
                :chat="item"
            />
          </template>
        </RecycleScroller>

        <ChatLine
            v-if="state.isLoading"
            :is-loading="true"
            v-for="_ in 8"
        />
    </div>
  </div>
</template>

<script setup>
import HeadNew from "@/components/Head.vue";

import {computed, onBeforeMount, reactive, toRaw, watch} from 'vue'
import {appAxios} from '@/axios'
import ChatLine from '@/views/chat/ChatLine.vue'
import store from "@/store/store.js"
import {indexedStore} from "@/indexedDB/indexedStore.js"
import {router} from "@/router/router.js"
import {useRoute} from "vue-router"
import FormTextInputSearch from '@/components/form/FormTextInputSearch.vue'
import UserCard from '@/components/UserCard.vue'

const route = useRoute()

// appAxios.chat.chatLastMessage('2024-10-30T07:41:39.322Z', 'lte')
// appAxios.chat.chatLastMessage('2024-10-30T07:41:39.322Z', 'gte')

async function getMessagesCache() {
  const messagesCache = await indexedStore.slices.chats.get(`chatList`)
  if (!messagesCache) {
    return null
  }
  if (messagesCache.userId !== store.state.user.profile.id) {
    return null
  }
  return messagesCache
}

async function setMessagesCache(messages) {
  const supportId = store.state.user.directories.support_chat_user.id
  let data = messages
      .filter(chat => chat.last_message_date || supportId === chat.user_from.id)

  const messagesCache = await getMessagesCache()
  if (messagesCache) {
    const usedIds = {}
    data.forEach(chat => usedIds[chat.id] = true)

    messagesCache.data.forEach(chat => {
      if (!usedIds[chat.id]) {
        data.push(chat)
      }
    })
  }

  data = data.sort((a, b) => new Date(b.last_message_date) - new Date(a.last_message_date))

  if (data.length) {
    const newMessageCache = {
      userId: store.state.user.profile.id,
      lastMessageDate: data.at(0).last_message_date,
      data,
    }
    await indexedStore.slices.chats.set(`chatList`, newMessageCache)
    return data
  }
  return []
}

const state = reactive({
  tab: route.query.tab ?? 'all',
  lastChatMessage: null,
  isLoading: true,
  chats: [],
})

const unreadCount = computed(() => {
  let unreadMessages = 0

  state.chats.forEach(chat => {
    unreadMessages += chat.unchecked_messages_count
  })

  if (unreadMessages) {
    const num = Number(unreadMessages)
    if (num > 99) {
      return `99+`
    }
  }
})

const tabChats = computed(() => {
  switch (state.tab) {
    case 'all':
      return state.chats
    case 'unread':
      return state.chats.filter(chat => {
        return chat.unchecked_messages_count
      })
  }
})

async function checkProfilesMessages(uncheckedMessagesValue) {
  let needUpdate = true
  // const uncheckedMessages = JSON.parse(JSON.stringify(toRaw(uncheckedMessagesValue)))
  // state.chats.forEach(chat => {
  //   if (!chat.last_message) {
  //     return
  //   }
  //   chat.last_message.checked_at = chat.last_message.created_at
  // })
  // uncheckedMessages.forEach(item => {
  //   const id = item.chat_id
  //   const found = state.chats.find(chat => {
  //     return String(chat.id) === String(id)
  //   })
  //   if (found) {
  //     found.unchecked_messages_count = item.count
  //     if (item.count === 0) {
  //       if (found.last_message) {
  //         found.last_message.checked_at = found.last_message.created_at
  //       }
  //     } else {
  //       found.last_message = item.last_message
  //     }
  //   } else {
  //     if (item.count > 0) {
  //       needUpdate = true
  //     }
  //   }
  // })
  if (needUpdate) {
    await indexedStore.slices.base.invalidate('chat-chatLastMessage')
    await indexedStore.slices.base.invalidate('chat-fetchAll')
    const messagesCache = await getMessagesCache()
    let res = null
    let axiosRes = null

    if (messagesCache) {
      const {response, axiosResponse} = await appAxios.chat.chatLastMessage(messagesCache.lastMessageDate)
      res = response
      axiosRes = axiosResponse
    } else {
      const {response, axiosResponse} = await appAxios.chat.fetchAll()
      res = response
      axiosRes = axiosResponse
    }
    const chats = await setMessagesCache(res.data)

    console.log('chats', chats)

    if (store.state.user.profile.id === store.state.user.directories.support_chat_user.id) {
      state.chats = [
        ...chats
            .filter(chat => chat.last_message)
            .sort((a, b) => new Date(b.last_message?.created_at) - new Date(a.last_message?.created_at))
      ]
    } else {
      state.chats = [
        ...chats
            .filter(chat => store.state.user.directories.support_chat_user.id === chat.user_from.id),
        ...chats
            .filter(chat => chat.last_message)
            .filter(chat => store.state.user.directories.support_chat_user.id !== chat.user_from.id && chat.last_message)
            .sort((a, b) => new Date(b.last_message?.created_at) - new Date(a.last_message?.created_at))
      ]
    }
  }
}

watch(() => store.state.user.profile.unchecked_messages_count, async uncheckedMessages => {
  await checkProfilesMessages(uncheckedMessages)
})

onBeforeMount(async () => {
  // state.isLoading = true
  const messagesCache = await getMessagesCache()
  let res = null
  let axiosRes = null

  if (messagesCache) {
    const {response, axiosResponse} = await appAxios.chat.chatLastMessage(messagesCache.lastMessageDate)
    res = response
    axiosRes = axiosResponse
  } else {
    const {response, axiosResponse} = await appAxios.chat.fetchAll()
    res = response
    axiosRes = axiosResponse
  }
  const chats = await setMessagesCache(res.data)

  if (store.state.user.profile.id === store.state.user.directories.support_chat_user.id) {
    state.chats = [
      ...chats
          .filter(chat => chat.last_message)
          .sort((a, b) => new Date(b.last_message?.created_at) - new Date(a.last_message?.created_at))
    ]
  } else {
    state.chats = [
      ...chats
          .filter(chat => store.state.user.directories.support_chat_user.id === chat.user_from.id),
      ...chats
          .filter(chat => chat.last_message)
          .filter(chat => store.state.user.directories.support_chat_user.id !== chat.user_from.id && chat.last_message)
          .sort((a, b) => new Date(b.last_message?.created_at) - new Date(a.last_message?.created_at))
    ]
  }
  state.isLoading = false

  if (!res.status) {
    axiosRes.then(async r => {
      const chats = await setMessagesCache(r.data)
      if (store.state.user.profile.id === store.state.user.directories.support_chat_user.id) {
        state.chats = [
          ...chats
              .filter(chat => chat.last_message)
              .sort((a, b) => new Date(b.last_message?.created_at) - new Date(a.last_message?.created_at))
        ]
      } else {
        state.chats = [
          ...chats
              .filter(chat => store.state.user.directories.support_chat_user.id === chat.user_from.id),
          ...chats
              .filter(chat => chat.last_message)
              .filter(chat => store.state.user.directories.support_chat_user.id !== chat.user_from.id && chat.last_message)
              .sort((a, b) => new Date(b.last_message?.created_at) - new Date(a.last_message?.created_at))
        ]
      }
    })
  }

  // await checkProfilesMessages(store.state.user.profile.unchecked_messages_count)

  if (state.tab === 'unread' && unreadCount.value === 0) {
    await router.replace({name: 'ChatList', query: {tab: 'all'}})
  }
})

</script>

<style lang="scss" scoped>
@import "@/assets/variables";

.pageWrapper {
  min-height: calc(100vh - 80px);
  min-height: calc(100dvh - 80px);
  max-height: calc(100vh - 80px);
  max-height: calc(100dvh - 80px);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.pageWrapperOverflow {
  overflow-y: auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.chatList {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.tabs {
  display: flex;
  padding: 0;

  button {
    width: 100%;
    background: #171717;
    height: 42px;
    font-size: 14px;
    border-bottom: 2px #494949 solid;
    color: #FFFFFF;

    &.disabled {
      color: #A6A5A5;
    }

    &.selected {
      border-bottom: 2px #CF2E2E solid;
    }
  }
}

.redCircle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: inline-block;
  background: #CF2E2E;
  margin-left: 4px;
  font-size: 10px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
}

.holder_in {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-x: auto;
}

</style>
