import localforage from "localforage"

export class BaseIndexedSlice {
  indexedSlice = null
  indexedObjectCache = null
  uidField = 'id'

  constructor(name) {
    this.indexedObjectCache = {}
    this.indexedSlice = localforage.createInstance({
      name,
    })
  }

  async init() {
    await this.indexedSlice.iterate((value, key, iterationNumber) => {
      this.indexedObjectCache[key] = value
    })
  }

  async clear() {
    this.indexedObjectCache = {}
    await this.indexedSlice.clear()
  }

  async get(key) {
    return this.indexedObjectCache[key]
  }

  async set(key, data) {
    this.indexedObjectCache[key] = data
    this.indexedSlice.setItem(key, data)
  }

  async update(key, data) {
    this.indexedObjectCache[key] = data
    await this.indexedSlice.setItem(key, data)
  }

  async updateBulk(key) {
    const cache = this.indexedObjectCache[key]
  }

  async cacheResponse(rawKey, axiosInstance, data) {
    const key = rawKey
    const cache = this.indexedObjectCache[key]
    if (cache) {
      return cache
    }
    const responsePromise = axiosInstance(data)
    responsePromise.then(async (res) => {
      const data = {
        data: res.data
      }
      this.indexedObjectCache[key] = data
      await this.indexedSlice.setItem(key, data)
    })
    return await responsePromise
  }

  async indexingResponse(rawKey, responsePromise) {
    const key = rawKey
    responsePromise.then(async (res) => {
      const data = {
        data: res.data
      }
      this.indexedObjectCache[key] = data
      await this.indexedSlice.setItem(key, data)
    })
    const cache = this.indexedObjectCache[key]
    if (cache) {
      return cache
    }
    return await responsePromise
  }

  async indexingResponse2(rawKey, responsePromise) {
    const key = rawKey
    responsePromise.then(async (res) => {
      const data = {
        data: res.data
      }
      this.indexedObjectCache[key] = data
      await this.indexedSlice.setItem(key, data)
    })
    const cache = this.indexedObjectCache[key]
    if (cache) {
      return {
        response: cache,
        axiosResponse: responsePromise,
      }
    }
    return {
      response: await responsePromise,
      axiosResponse: responsePromise,
    }
  }

  async invalidate(key) {
    delete this.indexedObjectCache[key]
    await this.indexedSlice.removeItem(key)
  }
}
